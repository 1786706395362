import React from "react"
import styled from "styled-components"
import { COLORS } from "../constants"

const MainTitleContent = styled.div`
  text-align: center;
  margin-bottom: 50px;
`

const MainTitleText = styled.h1`
  font-size: 4em;
  font-family: "Racing Sans One";
  color: ${COLORS.BLUE};
  margin: 0 0 40px 0;
  padding-left: 20px;
  padding-right: 20px;
  font-weight: normal;
  display: block;

  @media(max-width: 800px){
    font-size: 3em;
  }
`

const MainEffect = styled.img`
  display: block;
  margin: 0 auto;
`

export const SectionTitle = styled.h1`
  font-family: 'Love Ya Like A Sister', sans-serif;
  text-align: center;
  margin: 0 0 40px 0;
  font-size: 3.5em;
  font-weight: 400;
  color: ${COLORS.WHITE};

  @media (max-width: 600px){
    font-size: 2.5em;
  }
`

export const MainTitle = ({ children }) => (
  <MainTitleContent data-aos="fade-up" data-aos-once="true">
    <MainTitleText>{children}</MainTitleText>
    <MainEffect src={require("../images/title-effect.svg")} alt="" />
  </MainTitleContent>
)
