import styled, { css } from "styled-components"
import { darken } from "polished"

const BUTTON = {
  BG: "#292929",
  COLOR: "#FFFFFF"
}

export const Button = styled.button`
  font-family: inherit;
  font-size: 0.8em;
  display: inline-block;
  height: 33px;
  line-height: 32px;
  margin: 0 5px 5px 0;
  padding: 0 20px 0 20px;
  cursor: pointer;
  font-weight: bold;
  text-transform: uppercase;
  transition: all 0.1s linear 0s;
  text-align: center;
  vertical-align: middle;
  position: relative;
  bottom: 0px;
  border: none;
  color: ${BUTTON.COLOR};

  &:hover {
    text-decoration: none;
  }

  &:active {
    bottom: 4px;
  }

  ${props =>
    props.bg &&
    css`
      background: ${props.bg};
      &:hover {
        background: ${darken(0.1, props.bg)};
      }
    `};

  ${props =>
    props.color &&
    css`
      color: ${props.color};
    `};

  ${props =>
    props.rounded &&
    css`
      border-radius: 100px;
    `};
`

Button.defaultProps = {
  bg: BUTTON.BG,
  color: BUTTON.COLOR
}
